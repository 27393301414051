
//Generic Colors
$white: #fff;
$black: #000;
$grey: #888;
$border-grey: #ccc;
$border-grey-02: #e1e1e1;
$border-grey-03: #e7e7e7;


//Primary Colors:
$primary-blue: #003a70;
$primary-orange: #d36816;
$primary-black: #212529;

//Secondary Colors:
$secondary-blue: #007dba;
$secondary-grey: #707372;
$secondary-light-grey: #a0a0a0;
$secondary-yellow: #ffb81c;
$secondary-orange: #f0950c;

//Other Colors:
$accent-white-smoke: #f4f2f2;
$accent-rust: #b15006;
$accent-blue: #1f84e1;
$accent-blue-100: #0078b5;
$accent-dark-blue: #006597;
$accent-light-blue: #98b8d6;
$accent-alice-blue: #f4f9fe;
$accent-silver: #c4c4c4;
$dark-green: #73b564;
$accent-green: #345e19;
$accent-light-green: #609140;
$accent-medium-green: #639543;
$accent-emerald: #4ebe60;
$accent-grey: #ccc;
$accent-grey-02: #f2f2f2;
$accent-grey-03: #b8b3b3;
$accent-grey-04: #eee;
$accent-grey-05: #666;
$accent-grey-06: #949494;
$accent-grey-07: #e5e5e5;
$accent-grey-08: #e0e0e0;
$accent-grey-09: #fbfbfb;
$accent-grey-10: #f6f6f6;
$accent-grey-11: #979797;
$accent-snow: #f4f4f4;
$accent-red: #f00000;
$accent-dark-red: #a50000;
$accent-dark-red-02: #db3737;
$accent-light-red: #d70101;
$accent-red-warning: #d84545;
$accent-punch: #db3b31;
$accent-yellow-warning: #f9a118;
$accent-white-rose: #fef2f2;
$accent-sea-shell: #fef8f3;
$accent-light-slate-gray: #78909c;
$accent-mercury: #e6e6e6;
$accent-mine-shaft: #333;
$accent-alabster: #fafafa;
$accent-azure: #007bff;
$accent-yellow: #873f08;
$accent-dark-blue: #004f75;
$accent-abbey: #474c52;
$accent-sushi: #74c142;
$accent-punch: #db3b31;
$accent-highlight: #fff3d6;
$accent-light-grey-01: #fcfcfc;
$accent-light-grey-02: #c8c8c8;
$accent-light-grey-03: #b4b4b4;
$accent-light-grey-04: #f3f4f5;

//Background Colors:
$accent-sea-shell: #fef8f3;
$accent-pale-blue: #f2f8fc;
$accent-floral-white: #fffbf4;
$accent-panache: #f8fbf6;
$accent-pale-yellow: #fff9e9;
