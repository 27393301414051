@import 'styles/partials/fonts';
@import 'styles/partials/colors';
@import 'styles/partials/breakpoints';
@import 'styles/partials/mixins';

.splashWrapper {
  display: flex;
  height: calc(100vh - #{$main-nav-header-height});
  width: 100vw;

  &Content {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;
    overflow-y: auto;

    padding: 4rem 4rem 2rem;

    @media (max-width: $large) {
      padding: 3rem 3rem 2rem;
    }

    @media (max-width: $xlarge) {
      flex: 3;
    }
  }

  &Footer {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    font-size: 0.95rem;

    > a {
      padding-right: 15px;
      position: relative;
      border-right: 1px solid $accent-grey;
    }

    > div {
      margin-left: 20px;
    }
  }

  .background {
    flex: 3;
    border-left: 1px solid $accent-silver;
    box-sizing: border-box;
    background: url('/images/backgrounds/login-bg.png');
    background-color: $white;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &Tag {
      position: absolute;
      bottom: 12px;
      right: 20px;
      color: $white;
      font-size: 1rem;
      font-weight: 600;
    }
  }
}
