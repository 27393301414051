@import 'styles/partials/fonts';
@import 'styles/partials/colors';
@import 'styles/partials/breakpoints';
@import 'styles/partials/mixins';

.modal {
  h2 {
    font-family: $heading-font;
    color: $primary-blue;
    font-size: 30px;
    line-height: 42px;
    font-weight: 400;
    margin-bottom: 29px;
  }

  p {
    font-size: 18px;
    line-height: 26px;
  }

  button {
    width: 294px;
    margin-top: 12px;
    margin-bottom: 44px;

    a:visited,
    a:active {
      text-decoration: none;
    }
  }
}

.login {
  h1 {
    margin-bottom: 40px;

    @media (max-width: $large) {
      font-size: 31px;
    }
  }

  &Form {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    p {
      margin-bottom: 0;
    }

    form {
      > div {
        margin: 0 0 22px;
      }

      button {
        width: 100%;
      }
    }
  }

  &Info {
    font-size: 15px;
    line-height: 22px;
    margin-top: 22px;

    &Reset {
      margin: 20px 0;
    }
  }

  &Links {
    a {
      &:first-of-type {
        margin-right: 10px;
      }

      &:last-of-type {
        margin-left: 10px;
      }
    }
  }

  .resetPasswordLink {
    margin-bottom: 2.5rem;
  }

  .internalLoginButton {
    margin-top: 2.5rem;
  }
}

.localInternalLoginButton {
  padding-top: 1rem;
}
